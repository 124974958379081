import React, { ReactNode } from 'react';

import { Button } from '@packfleet/ui';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { twMerge } from 'tailwind-merge';

export const Popover = PopoverPrimitive.Root;

interface PopoverTriggerProps {
  children: ReactNode;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  asChild?: boolean;
  onClick?: () => void;
}

export const PopoverTrigger = ({
  children,
  active,
  disabled,
  className,
  ...props
}: PopoverTriggerProps) => (
  <PopoverPrimitive.Trigger
    className={twMerge(
      'flex items-center gap-2 rounded border-secondary px-2 py-1',
      disabled ? '' : 'border',
      active
        ? 'border-secondary md:hover:border-slate10'
        : 'md:border-transparent',
      className,
    )}
    disabled={disabled}
    {...props}
  >
    {children}
  </PopoverPrimitive.Trigger>
);

export const PopoverContent = ({
  children,
  className,
  variant = 'default',
  ...props
}: PopoverPrimitive.PopoverContentProps & {
  className?: string;
  variant?: 'dropdown' | 'default';
}) => {
  return (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        className={twMerge(
          'bg-primary p-4 shadow-3xl',
          variant === 'default'
            ? 'animation-scaledFadeIn rounded'
            : 'animation-slideDown mt-1 min-w-[15rem] rounded-lg px-2',
          className,
        )}
        sideOffset={5}
        align="start"
        style={{ zIndex: 31 }}
        {...props}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  );
};

type FieldGroupProps = {
  children?: ReactNode;
  label?: ReactNode;
};

type PopoverListItemProps = {
  children?: ReactNode;
  onClick?: () => void;
};

export const PopoverListItem = ({
  children,
  onClick,
}: PopoverListItemProps) => (
  <Button
    unstyled
    className="flex w-full cursor-pointer py-3 px-3 text-sm leading-none text-primary antialiased hover:bg-slate2"
    onClick={onClick}
  >
    <div className="flex items-center gap-3">{children}</div>
  </Button>
);

export const PopoverFieldGroup = ({ children, label }: FieldGroupProps) => (
  <div className="px-3">
    <div className="pb-2 text-sm text-slate9">{label}</div>
    {children}
  </div>
);
