import { Icons } from '@/components/navbar/Icons';
import { extractErrorMessage } from '@/errors/utilities';
import {
  AdvanceTrackingInfo,
  CollectionWithShipmentCountsFragment,
  DeleteCollectionInput,
  DeleteCollectionMutation,
  LiveTrackingInfo,
  OrganizationFragment,
  ProofOfCollectionFragment,
  ProofOfCompletionStatus,
  SkipCollectionInput,
  SkipCollectionMutation,
  useCheckCollectionCancellationTermsLazyQuery,
} from '@/generated/graphql';
import { formatProofOfCompletionStatus } from '@/utilities/proof-of-completion';
import { FetchResult } from '@apollo/client';
import { nowLocal, parseOptionalInstant } from '@packfleet/datetime';
import { Button, Shipment } from '@packfleet/ui';
import cs from 'classnames';
import React, { useState } from 'react';
import { useOrganizationTimezone } from '../../hooks/timezone';
import { formatCollectionLocation } from '../../utilities/collection-locations';
import { formatCollection } from '../../utilities/collections';
import { formatTime } from '../../utilities/date';
import { pluralize } from '../../utilities/pluralize';
import { formatStartEndTime } from '../../utilities/time';
import WithAlert from '../modal/WithAlert';
import CollectionTrackingMap from '../tracking/CollectionTrackingMap';
import { CollectionTerms } from './CollectionTerms';

export type Props = {
  collection: CollectionWithShipmentCountsFragment;
  organization: OrganizationFragment;
  liveTracking?: LiveTrackingInfo | null;
  proofOfCollection?: ProofOfCollectionFragment | null;
  advanceTracking?: AdvanceTrackingInfo | null;
  onSkipCollection: (
    input: SkipCollectionInput,
  ) => Promise<FetchResult<SkipCollectionMutation>>;
  onDeleteCollection: (
    input: DeleteCollectionInput,
  ) => Promise<FetchResult<DeleteCollectionMutation>>;
};

const LiveCollectionCard = ({
  collection,
  organization,
  liveTracking,
  advanceTracking,
  onSkipCollection,
  onDeleteCollection,
  proofOfCollection,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const orgTimezone = useOrganizationTimezone();
  const [checkCollectionCancellationTerms, { data, error }] =
    useCheckCollectionCancellationTermsLazyQuery({
      variables: { input: { collectionId: collection.id } },
      fetchPolicy: 'network-only',
    });
  const checkTermsError =
    error?.message || data?.checkCollectionCancellationTerms?.error?.message;
  const terms = data?.checkCollectionCancellationTerms?.terms;

  const numShipments = collection.numShipments ?? 0;
  const numPacks = collection.numPacks ?? 0;
  const allShipmentsCollected = collection.allShipmentsCollected;
  const numExternalShipments = collection.numExternalShipments ?? 0;
  const numExternalPacks = collection.numExternalPacks ?? 0;
  const totalShipmentCount = numShipments + numExternalShipments;

  const isSkipped = collection.skipped;
  const driverName = liveTracking?.driverName;
  const eta = parseOptionalInstant(liveTracking?.estimatedTime);
  const windowStart = parseOptionalInstant(liveTracking?.timeWindowStart);
  const windowStartZoned = windowStart?.toZonedDateTimeISO(orgTimezone);
  const windowEnd = parseOptionalInstant(liveTracking?.timeWindowEnd);
  const windowEndZoned = windowEnd?.toZonedDateTimeISO(orgTimezone);
  const advanceWindowStart = parseOptionalInstant(
    advanceTracking?.timeWindowStart,
  );
  const advanceWindowStartZoned =
    advanceWindowStart?.toZonedDateTimeISO(orgTimezone);
  const advanceWindowEnd = parseOptionalInstant(advanceTracking?.timeWindowEnd);
  const advanceWindowEndZoned =
    advanceWindowEnd?.toZonedDateTimeISO(orgTimezone);
  const now = nowLocal(orgTimezone);
  const minsAway = eta
    ?.toZonedDateTimeISO(orgTimezone)
    .since(now)
    .total({ unit: 'minutes' });
  const showMinsAway = minsAway != null && minsAway < 60;
  const driverIsClose = minsAway != null && minsAway < 5;
  const collectionCompleted = liveTracking?.completedAt != null;

  const collectionFailed =
    proofOfCollection?.status &&
    proofOfCollection?.status !== ProofOfCompletionStatus.Collected;

  const collectionStatus = collectionFailed
    ? formatProofOfCompletionStatus(proofOfCollection.status)
    : undefined;

  const { isReturnLocation } = collection.location;

  return (
    <div className={cs('relative flex flex-col-reverse lg:flex-row')}>
      <div className="flex flex-1 flex-col p-6">
        <strong>
          {formatStartEndTime(collection.startTime, collection.endTime)}
        </strong>
        <div>
          {isReturnLocation ? 'A return ' : null}from{' '}
          {formatCollectionLocation(collection.location)}
        </div>
        <div className="mt-4">
          <strong>
            {collectionCompleted ? (
              <>
                {collectionFailed ? (
                  <div>
                    <div className="text-danger">{collectionStatus}</div>
                    {proofOfCollection?.notes}
                  </div>
                ) : (
                  <span>Collection completed</span>
                )}
              </>
            ) : liveTracking ? (
              <span>
                {driverName ?? 'Your driver'}{' '}
                {driverIsClose ? (
                  <span>is a few minutes away</span>
                ) : showMinsAway ? (
                  <span>is {minsAway.toFixed()} min away</span>
                ) : windowStartZoned && windowEndZoned ? (
                  <span>
                    will arrive between <br />{' '}
                    {formatTime(windowStartZoned.toPlainTime())} and{' '}
                    {formatTime(windowEndZoned.toPlainTime())}
                  </span>
                ) : advanceWindowStartZoned && advanceWindowEndZoned ? (
                  <span>
                    <span>
                      will arrive between <br />{' '}
                      {formatTime(advanceWindowStartZoned.toPlainTime())} and{' '}
                      {formatTime(advanceWindowEndZoned.toPlainTime())}
                    </span>
                  </span>
                ) : (
                  <span>is on the way to you</span>
                )}
              </span>
            ) : (
              <span>Weʼll assign you a driver and 1-hour ETA window soon</span>
            )}
          </strong>
        </div>
        {!isSkipped && !collectionCompleted ? (
          <div
            className={cs('mt-6 font-medium', {
              'text-warning': totalShipmentCount === 0,
            })}
          >
            {isReturnLocation
              ? null
              : totalShipmentCount === 0
                ? 'You havenʼt added any shipments'
                : allShipmentsCollected
                  ? `All shipments collected`
                  : !organization.externalShipmentManagementEnabled
                    ? `${totalShipmentCount} ${pluralize(
                        totalShipmentCount,
                        'shipment',
                        'shipments',
                      )}${
                        numPacks !== numShipments
                          ? ` (${numPacks} ${pluralize(numPacks, 'pack', 'packs')})`
                          : ''
                      } to collect`
                    : null}

            {organization?.externalShipmentManagementEnabled &&
            (numPacks || numExternalPacks) ? (
              <div className="flex flex-row space-x-8 border-t border-slate5 pt-6">
                {numPacks ? (
                  <DeliveredByCounter
                    shipmentCount={numShipments}
                    packCount={numPacks}
                    carrierIcon={<Icons.packfleet />}
                  />
                ) : null}
                {/* TODO: In future, if/when we have more external carriers, we'll want to provide a way of mapping the external shipments per carrier here */}
                {numExternalPacks ? (
                  <DeliveredByCounter
                    shipmentCount={numExternalShipments}
                    packCount={numExternalPacks}
                    carrierIcon={
                      <Shipment.ExternalCarrierLogo
                        carrierCode={
                          collection.location.externalCarrier?.carrierCode ?? ''
                        }
                      />
                    }
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="flex-1" />
        <div className="mt-6">
          {totalShipmentCount === 0 && !collectionCompleted ? (
            <WithAlert
              button={
                <Button s="small" color="neutral" mode="outline">
                  {isReturnLocation ? 'Skip this return' : 'Skip collection'}
                </Button>
              }
              onOpen={async () => {
                await checkCollectionCancellationTerms();
              }}
              title={
                isReturnLocation
                  ? 'Cancel this return collection?'
                  : 'Skip this collection?'
              }
              body={
                <div>
                  <p className="mb-2">
                    Weʼll no longer come to collect{' '}
                    {formatCollection(collection, orgTimezone, true)}
                  </p>
                  {checkTermsError ? (
                    <p className="text-danger mb-2">
                      Something went wrong checking the cancllation conditions
                    </p>
                  ) : null}
                  <CollectionTerms terms={terms} tz={orgTimezone} />
                </div>
              }
              confirmText={'Yes, Skip'}
              onConfirm={async () => {
                if (collection.recurringCollectionId != null) {
                  const result = await onSkipCollection({
                    collectionId: collection.id,
                    feeAccepted: terms?.cancellationFee
                      ? {
                          amount: terms.cancellationFee.amount,
                          currencyCode: terms.cancellationFee.currencyCode,
                        }
                      : null,
                  });
                  const errorMsg = extractErrorMessage(
                    result,
                    'skipCollection',
                  );
                  if (errorMsg) {
                    setErrorMessage(errorMsg);
                  } else {
                    setErrorMessage('');
                  }
                } else {
                  const result = await onDeleteCollection({
                    collectionId: collection.id,
                    feeAccepted: terms?.cancellationFee
                      ? {
                          amount: terms.cancellationFee.amount,
                          currencyCode: terms.cancellationFee.currencyCode,
                        }
                      : null,
                  });
                  const errorMsg = extractErrorMessage(
                    result,
                    'deleteCollection',
                  );
                  if (errorMsg) {
                    setErrorMessage(errorMsg);
                  } else {
                    setErrorMessage('');
                  }
                }
              }}
              cancelText="Cancel"
              onCancel={() => null}
            />
          ) : null}
          <div className="mt-4">
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
          </div>
        </div>
      </div>
      {liveTracking && !collectionCompleted ? (
        <div className="lg:min-h-full h-64 w-full lg:h-auto lg:max-w-xl lg:flex-1">
          <CollectionTrackingMap
            senderEmoji={organization.emoji}
            liveInfo={liveTracking}
          />
        </div>
      ) : null}
    </div>
  );
};

const DeliveredByCounter = ({
  shipmentCount,
  packCount,
  carrierIcon,
}: {
  shipmentCount: number;
  packCount: number;
  carrierIcon: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col whitespace-nowrap">
      <span className="flex items-center text-sm font-semibold">
        <span>Delivered by</span>
        <span className="ml-1.5">{carrierIcon}</span>
      </span>

      <span className="text-xl">
        <span>
          {shipmentCount} {pluralize(shipmentCount, 'shipment', 'shipments')}
        </span>
        {shipmentCount !== packCount ? (
          <span className="ml-1.5">
            ({packCount} {pluralize(packCount, 'pack', 'packs')})
          </span>
        ) : null}
      </span>
    </div>
  );
};

export default LiveCollectionCard;
