import { EventName, useAnalytics } from '@packfleet/analytics';
import { Button } from '@packfleet/ui';
import { useCallback } from 'react';
import { IoClose } from 'react-icons/io5';

import { useSavedState } from '@/hooks/useSavedState';
import LinkButton from '../button/LinkButton';
import Heading from '../heading/Heading';

const getLocalStorageKey = (id: string) => `broadcast_message_is_visible_${id}`;

type Props = {
  id: string;
  text: string;
  heading: string | null | undefined;
  ctaText: string | null | undefined;
  ctaLink: string | null | undefined;
};

export const BroadcastMessage = ({
  id,
  text,
  heading,
  ctaText,
  ctaLink,
}: Props) => {
  const [show, setShow] = useSavedState(true, getLocalStorageKey(id));
  const analytics = useAnalytics();

  const onClose = useCallback(() => {
    analytics?.track(EventName.dismissedBroadcastMessage, { id });
    setShow(false);
  }, [setShow, analytics, id]);

  const onClick = useCallback(() => {
    analytics?.track(EventName.clickedBroadcastMessage, { id });
  }, [analytics, id]);

  if (!show) {
    return null;
  }

  return (
    <div className="mb-8 py-4 pl-5 pr-2 from-[#d0edf7] to-[#e8f6fb] bg-gradient-to-r rounded-lg border border-secondary flex justify-between text-brandDark antialiased gap-2">
      <div className="flex flex-col justify-center">
        {heading ? (
          <Heading level={2} className="mb-1">
            {heading}
          </Heading>
        ) : null}
        <p>{text}</p>
      </div>
      <div className="flex shrink-0">
        {ctaText && ctaLink ? (
          <LinkButton
            className="self-center"
            href={ctaLink}
            target="_blank"
            s="small"
            onClick={onClick}
          >
            {ctaText}
          </LinkButton>
        ) : null}
        <Button
          iconClassName="text-xl"
          color="neutral"
          mode="text"
          className="w-6 h-6 mt-[-.5rem]"
          aria-label="Close"
          icon={IoClose}
          onClick={onClose}
        />
      </div>
    </div>
  );
};
