import { Button, Loading } from '@packfleet/ui';
import React, { ReactNode } from 'react';
import { TbX } from 'react-icons/tb';
import LinkButton from '../button/LinkButton';
import { Heading } from '../heading/Heading';

const variantToClassName = {
  info: 'bg-blue5 border-blue6 text-brandDark',
  warning: 'bg-warningLight border-warning',
  success: 'bg-green11/10 border-green13/20 text-primary',
  error: 'bg-red5 border-red6',
};

type Variant = 'info' | 'warning' | 'success' | 'error';
type Props = {
  title?: string;
  description?: string;
  ctaText?: string;
  onDismiss?: () => Promise<void>;
  variant: Variant;
  icon?: ReactNode;
  isDismissing?: boolean;
} & (
  | {
      onClick?: () => Promise<void>;
      href?: never;
    }
  | {
      href?: string;
      onClick?: never;
    }
);

const Banner = ({
  title,
  description,
  ctaText,
  onClick,
  href,
  onDismiss,
  variant,
  icon,
  isDismissing,
}: Props) => {
  const ctaButton = !!onClick ? (
    <Button color="brand" mode="outline" s="smaller" onClick={onClick}>
      {ctaText}
    </Button>
  ) : !!href ? (
    <LinkButton href={href} mode="outline" s="smaller">
      {ctaText}
    </LinkButton>
  ) : null;
  return (
    <div
      className={`py-4 px-8 mb-4 rounded-lg border flex items-center antialiased gap-16 ${variantToClassName[variant]}`}
    >
      <div className="flex gap-4 flex-grow items-center">
        {icon ? <div className="flex-shrink-0 h-8 w-8">{icon}</div> : null}
        <div className="flex flex-col justify-center min-w-0">
          <Heading level={3}>{title}</Heading>
          <span className="text-sm">{description}</span>
        </div>
      </div>
      <div className="flex gap-4 items-center flex-shrink-0">
        {ctaButton}
        {!isDismissing && onDismiss ? (
          <Button
            className="h-8 w-8 p-0"
            mode="outline"
            s="smaller"
            onClick={onDismiss}
          >
            <TbX />
          </Button>
        ) : null}
        <Loading className="my-0" when={isDismissing} />
      </div>
    </div>
  );
};

export default Banner;
