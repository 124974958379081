import { ReusableComponentProps } from '@packfleet/ui';
import cs from 'classnames';
import React, { ReactElement, ReactNode, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import * as uuid from 'uuid';
import FormDescription from './FormDescription';
import FormError from './FormError';
import FormLabel from './FormLabel';

export type Props = ReusableComponentProps & {
  label?: string;
  description?: string;
  error?: string | null;
  hint?: ReactNode;
  children?: ReactNode;
  labelClassName?: string;
};

export const FormElement = ({
  className,
  labelClassName,
  style,
  children,
  label,
  description,
  error,
  hint,
}: Props) => {
  const firstChild = React.Children.toArray(children)[0] as ReactElement;
  if (!firstChild) {
    throw new Error('FormElement must have at least one child');
  }
  const elementIDRef = useRef(firstChild.props.id ?? uuid.v4());
  const elementID = elementIDRef.current;
  const errorElementID = `${elementID}-error`;
  const descriptionElementID = `${elementID}-description`;

  return (
    <fieldset className={cs(className)} style={style}>
      <div className="flex justify-between">
        {label ? (
          <FormLabel
            className={twMerge('relative mb-1', labelClassName)}
            htmlFor={elementID}
          >
            {label}
          </FormLabel>
        ) : null}
        {hint}
      </div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as ReactElement, {
          'aria-describedby': error
            ? errorElementID
            : description
              ? descriptionElementID
              : undefined,
          style: {
            ...(child as ReactElement).props.style,
            outline: error ? '#bf1848 auto 2px' : undefined,
          },
        });
      })}
      {error ? (
        <FormError className="mt-1" id={errorElementID}>
          {error}
        </FormError>
      ) : null}
      {description ? (
        <FormDescription className="mt-1" id={descriptionElementID}>
          {description}
        </FormDescription>
      ) : null}
    </fieldset>
  );
};

export default FormElement;
