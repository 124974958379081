import { pluralize } from '@/utilities/pluralize';
import { Routes, route } from '@/utilities/routes';
import * as Alert from '@components/alert/Alert';
import LinkButton from '@components/button/LinkButton';
import { useCountImportableShipments } from './useCountImportableShipments';

export const ImportableShipmentsBanner = () => {
  const { totalImportableShipments, importableShipmentsIsPaginated } =
    useCountImportableShipments();

  if (!totalImportableShipments) {
    return null;
  }

  return (
    <Alert.Root className="mb-8  py-4 px-5" type={Alert.AlertType.info}>
      <div className="flex w-full items-center">
        <div className="flex w-full flex-wrap items-stretch justify-between">
          <Alert.Title className="mt-1 mb-4 md:mb-0">
            You have {importableShipmentsIsPaginated ? 'over ' : undefined}
            {totalImportableShipments}{' '}
            {pluralize(totalImportableShipments, 'shipment', 'shipments')} ready
            to be imported
          </Alert.Title>
          <LinkButton
            s="small"
            href={route(Routes.appShipments)}
            className="w-full md:w-auto"
          >
            <span className="font-bold">View shipments</span>
          </LinkButton>
        </div>
      </div>
    </Alert.Root>
  );
};
