import React, { ReactNode, useContext } from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';

import Heading from '../heading/Heading';

export enum AlertType {
  warning = 'warning',
  info = 'info',
  warningLight = 'warningLight',
}

const AlertContext = React.createContext<{
  type: AlertType;
}>({
  type: AlertType.warning,
});

const useAlert = () => useContext(AlertContext);

const getColorScheme = (type: AlertType) => {
  const background = {
    warning: 'bg-orange2',
    warningLight: 'bg-yellow2',
    info: 'bg-slate1',
  }[type];

  const border = {
    warning: 'border-orange5',
    warningLight: 'border-yellow5',
    info: 'border-slate6',
  }[type];

  const text = {
    warning: 'text-orange11',
    warningLight: 'text-yellow11',
    info: 'text-brandDark',
  }[type];

  return {
    background,
    border,
    text,
  };
};

export const Alert = ({
  children,
  className,
  type = AlertType.warning,
}: {
  className?: string;
  children: ReactNode;
  type?: AlertType;
}) => {
  const { text, background, border } = getColorScheme(type);

  return (
    <AlertContext.Provider
      value={{
        type,
      }}
    >
      <div
        className={twMerge(
          'rounded-lg border border-orange5 bg-orange2 px-8 py-6 antialiased',
          background,
          border,
          className,
        )}
      >
        <div className={twMerge('flex', text)}>{children}</div>
      </div>
    </AlertContext.Provider>
  );
};

export const AlertIcon = ({
  className,
  iconComponent: IconComponent = IoWarningOutline,
}: {
  className?: string;
  iconComponent?: React.ElementType;
}) => (
  <div className={twMerge('mr-4 h-7 w-7', className)}>
    <IconComponent className="h-full w-full" />
  </div>
);

export const AlertTitle = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  const { type } = useAlert();
  const { text } = getColorScheme(type);

  return (
    <Heading
      level={2}
      className={twMerge('mb-1 flex items-center', text, className)}
    >
      {children}
    </Heading>
  );
};

export const AlertDescription = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  const { type } = useAlert();
  const { text } = getColorScheme(type);

  return (
    <div
      className={twMerge('rounded pb-2 text-sm text-orange11', text, className)}
    >
      {children}
    </div>
  );
};

const Root = Alert;
const Icon = AlertIcon;
const Title = AlertTitle;
const Description = AlertDescription;

export { Root, Icon, Title, Description };
