import { todayLocal } from '@packfleet/datetime';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  CollectionFragment,
  CollectionLiveTrackingFragment,
  DeleteCollectionInput,
  ListCollectionsDocument,
  OrganizationFragment,
  SkipCollectionInput,
  useDeleteCollectionMutation,
  useSkipCollectionMutation,
  useUnskipCollectionMutation,
} from '../../generated/graphql';
import { useOrganizationTimezone } from '../../hooks/timezone';
import { isNextCollection } from '../../utilities/collections';
import { formatDate } from '../../utilities/date';
import { pluralize } from '../../utilities/pluralize';
import Heading from '../heading/Heading';
import CollectionCard from './CollectionCard';
import LiveCollectionCard from './LiveCollectionCard';

export type Props = {
  organization: OrganizationFragment;
  collections: CollectionFragment[];
  liveTracking: CollectionLiveTrackingFragment[];
};

const CollectionsCalendar: FunctionComponent<Props> = ({
  organization,
  collections,
  liveTracking,
}) => {
  const timezone = useOrganizationTimezone();
  const [skipCollection] = useSkipCollectionMutation();
  const [deleteCollection] = useDeleteCollectionMutation();
  const [unskipCollection] = useUnskipCollectionMutation();
  const refetchCollectionQueries = [
    {
      query: ListCollectionsDocument,
      variables: {
        input: {
          fromDate: formatDate(todayLocal(timezone)),
        },
      },
    },
  ];
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: deleteCollection
  const onDeleteCollection = useCallback(
    async (input: DeleteCollectionInput) => {
      return deleteCollection({
        variables: { input },
        refetchQueries: refetchCollectionQueries,
      });
    },
    // biome-ignore lint/correctness/useExhaustiveDependencies: refetchCollectionQueries changes on every re-render and should not be used as a hook dependency.
    [refetchCollectionQueries],
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: skipCollection
  const onSkipCollection = useCallback(
    async (input: SkipCollectionInput) => {
      return skipCollection({
        variables: { input },
        refetchQueries: refetchCollectionQueries,
      });
    },
    // biome-ignore lint/correctness/useExhaustiveDependencies: refetchCollectionQueries changes on every re-render and should not be used as a hook dependency.
    [refetchCollectionQueries],
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: unskipCollection
  const onUnskipCollection = useCallback(
    async (id: string) => {
      return unskipCollection({
        variables: {
          input: {
            collectionId: id,
          },
        },
        refetchQueries: refetchCollectionQueries,
      });
    },
    // biome-ignore lint/correctness/useExhaustiveDependencies: refetchCollectionQueries changes on every re-render and should not be used as a hook dependency.
    [refetchCollectionQueries],
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: timezone
  const enrichedCollections = useMemo(
    () =>
      collections.map((c) => ({
        isNext: isNextCollection(c, timezone),
        collection: c,
        tracking: liveTracking.find((t) => t.collection.id === c.id),
      })),
    [collections, liveTracking],
  );
  const liveCollections = useMemo(
    () => enrichedCollections.filter((c) => c.isNext && !c.collection.skipped),
    [enrichedCollections],
  );
  const liveIds = useMemo(
    () => new Set(liveCollections.map((l) => l.collection.id)),
    [liveCollections],
  );
  const otherCollections = useMemo(
    () => enrichedCollections.filter((c) => !liveIds.has(c.collection.id)),
    [enrichedCollections, liveIds],
  );
  const hasReturns = liveCollections.some(
    (c) => c.collection.location.isReturnLocation,
  );

  return (
    <div>
      {liveCollections.length > 0 ? (
        <div className="mb-4 w-full overflow-hidden rounded border border-primary bg-primary p-6 shadow-md">
          <Heading level={2} className="text-xl leading-none">
            Todayʼs{' '}
            {pluralize(
              liveCollections.length,
              hasReturns ? 'return' : 'collection',
              hasReturns ? 'collections and returns' : 'collections',
            )}
          </Heading>
          {liveCollections.map((c) => {
            return (
              <div key={c.collection.id} className="mt-4">
                <LiveCollectionCard
                  collection={c.collection}
                  organization={organization}
                  liveTracking={c.tracking?.liveInfo}
                  advanceTracking={c.tracking?.advanceInfo}
                  onSkipCollection={onSkipCollection}
                  onDeleteCollection={onDeleteCollection}
                  proofOfCollection={c.tracking?.proofOfCollection}
                />
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="overflow-x-scroll rounded pb-4">
        <div className="flex items-stretch">
          {otherCollections.map((c) => {
            return (
              <div className="mr-4 w-80 flex-shrink-0" key={c.collection.id}>
                <CollectionCard
                  isNext={c.isNext}
                  collection={c.collection}
                  onSkipCollection={onSkipCollection}
                  onUnskipCollection={onUnskipCollection}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CollectionsCalendar;
