import { CollectionCancellationTermsFragment } from '@/generated/graphql';
import { formatMoney } from '@/utilities/money';
import { Routes } from '@/utilities/routes';
import {
  formatIso8601ToFriendlyDateShort,
  formatOptionalIso8601ToTime,
  isToday,
  parseInstant,
} from '@packfleet/datetime';
import Link from '../link/Link';

type Props = {
  terms: CollectionCancellationTermsFragment | null | undefined;
  tz: string;
};

export const CollectionTerms = ({ terms, tz }: Props) => {
  if (terms?.noCancellationFeeDeadline)
    return (
      <p className="mb-2 text-secondary">
        Free cancellation until{' '}
        {formatOptionalIso8601ToTime(terms.noCancellationFeeDeadline, tz)}{' '}
        {isToday(
          parseInstant(terms.noCancellationFeeDeadline)
            .toZonedDateTimeISO(tz)
            .toPlainDate(),
          tz,
        )
          ? 'today'
          : formatIso8601ToFriendlyDateShort(
              terms.noCancellationFeeDeadline,
              tz,
            )}
        .
      </p>
    );

  if (terms?.cancellationFee) {
    return (
      <>
        <p className="mb-2 text-secondary text-warning">
          There is a {formatMoney(terms.cancellationFee)} fee for this
          last-minute cancellation.
        </p>
        <p className="mb-2 text-secondary">
          There may be additional fees if there is nothing to collect. For
          details see our{' '}
          <Link href={Routes.appSettingsInvoicing}>pricing</Link>.
        </p>
      </>
    );
  }

  return null;
};
