import React, { ReactNode } from 'react';

import LinkButton from '@components/button/LinkButton';

export const SectionButton = ({
  href,
  icon,
  title,
  description,
}: {
  href: string;
  icon: ReactNode;
  title: string;
  description: string;
}) => (
  <LinkButton
    href={href}
    unstyled
    className="w-full md:w-auto md:min-w-[300px]"
  >
    <div className="flex w-full items-center gap-3 rounded-lg border border-tableBorder bg-primary px-5 py-3 text-sm text-secondary hover:border-secondary hover:text-primary md:max-w-xs">
      <div className="shrink-0">{icon}</div>
      <div>
        <div className="font-medium text-primary">{title}</div>
        <div className="text-secondary">{description}</div>
      </div>
    </div>
  </LinkButton>
);
